import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./technical-assistance.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons"
import Workforce from "../data/indivdualWorkforce"

const IndividualWorkforce = () => {
  const [workforce, setWorkforce] = useState(
    Workforce.workforce
  )

  return (
    <Layout>
      <SEO title="Individual Workforce" />
      <div>
        <div className="container primary-bg">
        <h1 className="small-padding">
          Individual Workforce Support
          </h1>
          <div>
            <p className="small-padding">
              Our people are what ultimately make Miami-Dade the world-class business community it is. While primarily focused on small business needs, a lot of the resources may also be helpful for individuals. Additionally, workforce resources specifically for individuals are outlined below.
            </p>
          </div>
          <hr />
          <section className="container">
            {workforce
              .sort((a, b) =>
                a.Name > b.Name ? 1 : -1
              )
              .map((workforce, index) => {
                return (
                  <div className="card">
                    <label className="additonalFundingCardTitle">
                      {workforce.Name}
                    </label>
                    <div className="rowSpace">
                      <FontAwesomeIcon
                        className="iconStyle"
                        size="lg"
                        icon={faGlobe}
                      />
                      <a
                        className="techLinks"
                        href={workforce.link}
                        target="_blank"
                      >
                        {workforce.link}
                      </a>
                    </div>
                  </div>
                )
              })}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default IndividualWorkforce
