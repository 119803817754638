export const IndividualWorkforce = {
    workforce: [
      {
        "Name": "CareerSource South Florida",
        "link": "http://www.careersourcesfl.com/",
      },
      {
        "Name": "Florida Department of Economic Opportunity (DEO)",
        "link": "http://floridajobs.org/",
      },
      {
        "Name": "Florida DEO Reemployment Assistance",
        "link": "https://fldeo-flwork-prod1.pegacloud.net/prweb/app/default/tHGV_g6FQZMSYakWUervaQ%28%28*/!STANDARD",
      },
    ],
  }
  
  export default IndividualWorkforce
  